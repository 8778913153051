import useSWR from 'swr';

import { swrConfig } from '@/lib/swr/config';
import { fetcher } from '@/lib/swr/fetcher';
import type { LanguageResponse, MetaKeys } from '@/types/settings';

const useSettingsMeta = (metaKey: MetaKeys) => {
    const metaSwrKey = `/api/base/settings?metaKey=${metaKey}`;

    const { data, error, isLoading, mutate } = useSWR<LanguageResponse, Error>(
        metaSwrKey,
        fetcher<LanguageResponse>,
        swrConfig,
    );

    const meta = data?.data ?? null;

    return { meta, error, isLoading, mutate };
};

export { useSettingsMeta };
