import { DropdownMenuContent, DropdownMenuRoot, cn } from '@i2e/components';
import type { PropsWithChildren } from 'react';

import {
    DropdownActionsTrigger,
    DropdownDeleteAction,
    DropdownEditAction,
} from '@/components/dropdown-actions';
import { useUserStore } from '@/store/useUserStore';

export const HeaderRoot = ({
    children,
    className,
}: PropsWithChildren<{ className?: string | undefined }>) => {
    return <header className={cn('mb-6 mt-9 px-4 md:px-0', className)}>{children}</header>;
};

export const HeaderRow = ({
    children,
    className,
}: PropsWithChildren<{ className?: string | undefined }>) => {
    return (
        <div className={cn('flex items-center justify-between gap-5', className)}>{children}</div>
    );
};

export const HeaderTitle = ({ children, className }: PropsWithChildren<{ className?: string }>) => {
    return (
        <h1
            className={cn(
                'grow text-2xl font-bold leading-7 tracking-tightest text-neutral-250 first-letter:uppercase',
                className,
            )}
        >
            {children}
        </h1>
    );
};

export const HeaderSubTitle = ({
    children,
    className,
}: PropsWithChildren<{ className?: string | undefined }>) => {
    return <p className={cn('mt-4 text-sm text-gray-800', className)}>{children}</p>;
};

/**
 * HeaderRowActions
 */

interface HeaderRowActionsProps {
    onEdit?: () => void;
    onDelete?: () => void;
    permissions: {
        edit: string;
        destroy: string;
    };
}

export const HeaderRowActions = ({
    children,
    onEdit,
    onDelete,
    permissions: permissionSlug,
}: PropsWithChildren<HeaderRowActionsProps>) => {
    const { permissions } = useUserStore();

    const { edit, destroy } = permissionSlug;

    // Check if user has at least one permission to display the dropdown
    const isVisible =
        permissions?.capabilities?.includes(edit) ?? permissions?.capabilities?.includes(destroy);

    return (
        <DropdownMenuRoot modal={false}>
            <DropdownActionsTrigger disabled={!isVisible} />

            <DropdownMenuContent hidden={!isVisible}>
                {onEdit ? <DropdownEditAction onSelect={onEdit} permission={edit} /> : null}
                {onDelete ? (
                    <DropdownDeleteAction onSelect={onDelete} permission={destroy} />
                ) : null}

                {/* Pass as menu items in case if need extra options to select from */}
                {children}
            </DropdownMenuContent>
        </DropdownMenuRoot>
    );
};

/**
 * HeaderSingleRoot
 */

export const HeaderSingleRoot = ({
    children,
    className,
}: PropsWithChildren<{ className?: string | undefined }>) => {
    return <header className={cn('bg-white px-4 pb-4 pt-9 lg:px-9', className)}>{children}</header>;
};

export const HeaderSingleRow = ({
    children,
    className,
}: PropsWithChildren<{ className?: string | undefined }>) => {
    return <div className={cn('flex items-start justify-between', className)}>{children}</div>;
};

export const HeaderSingeTitleRow = ({
    children,
    className,
}: PropsWithChildren<{ className?: string | undefined }>) => {
    return <div className={cn('flex grow items-center gap-x-6', className)}>{children}</div>;
};
