import { Button, DropdownMenuItem, DropdownMenuTrigger, cn } from '@i2e/components';
import { DeleteIcon, EditIcon, ExpandMoreIcon } from '@in2event/icons';
import { useTranslation } from 'react-i18next';

import { RestrictedLaunchpad } from '../restricted/launchpad';

interface DropdownActionProps {
    onSelect: () => void;
    permission?: string;
}

export const DropdownActionsTrigger = ({
    className,
    disabled,
}: {
    className?: string;
    disabled?: boolean;
}) => {
    const { t } = useTranslation(['launchpad']);

    return (
        <DropdownMenuTrigger asChild>
            <Button
                disabled={disabled}
                className={cn(
                    'ml-auto aria-[expanded=true]:bg-neutral-90 aria-[expanded=true]:fill-white aria-[expanded=true]:text-white',
                    className,
                )}
            >
                {t('dropdown.actions.trigger')}
                <ExpandMoreIcon className="size-5" />
            </Button>
        </DropdownMenuTrigger>
    );
};

export const DropdownEditAction = ({ onSelect, permission }: DropdownActionProps) => {
    const { t } = useTranslation(['launchpad']);

    if (!permission) {
        return (
            <DropdownMenuItem onSelect={onSelect}>
                <EditIcon className="size-5" />
                {t('dropdown.actions.edit')}
            </DropdownMenuItem>
        );
    }

    return (
        <RestrictedLaunchpad permission={permission}>
            <DropdownMenuItem onSelect={onSelect}>
                <EditIcon className="size-5" />
                {t('dropdown.actions.edit')}
            </DropdownMenuItem>
        </RestrictedLaunchpad>
    );
};

export const DropdownDeleteAction = ({ onSelect, permission }: DropdownActionProps) => {
    const { t } = useTranslation(['launchpad']);

    if (!permission) {
        return (
            <DropdownMenuItem onSelect={onSelect}>
                <DeleteIcon className="size-5" />
                {t('dropdown.actions.delete')}
            </DropdownMenuItem>
        );
    }

    return (
        <RestrictedLaunchpad permission={permission}>
            <DropdownMenuItem onSelect={onSelect}>
                <DeleteIcon className="size-5" />
                {t('dropdown.actions.delete')}
            </DropdownMenuItem>
        </RestrictedLaunchpad>
    );
};
