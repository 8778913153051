'use client';

import i18next, { BackendModule, i18n } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { useEffect } from 'react';
import { initReactI18next, useTranslation as useTransAlias } from 'react-i18next';

import { Lang, mapLangToi18n } from './helpers';
import { useLocale } from './locale-provider';
import { Locales, cookieName, getOptions, languages } from './settings';

const runsOnServerSide = typeof window === 'undefined';
// https://carlogino.com/blog/nextjs-app-dir-i18n-cookie
// Initialize i18next for the client side
export function initializeI18next(resources: BackendModule, defaultLocale?: Locales | undefined) {
    i18next
        .use(initReactI18next)
        .use(LanguageDetector)
        .use(resources)
        .init({
            ...getOptions(defaultLocale),
            lng: undefined, // detect the language on the client
            detection: {
                // We only care about the cookie
                order: ['cookie'],
                // If `lookupCookie` is not set, it will use `i18next` as the cookie name
                lookupCookie: cookieName,
                // This will automatically update the cookie
                caches: ['cookie'],
            },
            debug: false,
            preload: runsOnServerSide ? languages : [],
        })
        // eslint-disable-next-line no-console -- We want to log the error
        .catch(() => console.error('Failed to initialize i18next'));
}

// Helper function to map language
function getMappedLanguage(lng: string): Locales {
    const isLang = /^(english|dutch|german|french)$/.test(lng);
    return isLang ? mapLangToi18n(lng as Lang) : (lng as Locales);
}

export function useTranslation(...args: Parameters<typeof useTransAlias>) {
    let lng = useLocale();
    // Todo: this is a temporary fix for the language issue as in the staff portal it's saved as english, dutch, etc instead of en, nl, etc in the cookie
    // Todo: This will be removed once the language is saved as en, nl, etc in the cookie (Some time in the future as clients are probably using the current implementation)
    lng = getMappedLanguage(lng);

    const translator = useTransAlias(...args);
    const { i18n } = translator;

    // Run content is being rendered on server side
    if (runsOnServerSide && lng && i18n.resolvedLanguage !== lng) {
        i18n.changeLanguage(lng);
    } else {
        // Use our custom implementation when running on client side
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useCustomTranslationImplementation(i18n, lng);
    }

    return translator;
}

function useCustomTranslationImplementation(i18n: i18n, lng: Locales) {
    // This effect changes the language of the application when the lng prop changes.
    useEffect(() => {
        if (!lng || i18n.resolvedLanguage === lng) return;
        i18n.changeLanguage(lng);
    }, [lng, i18n]);
}
