import {
    Avatar,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuRoot,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
    Text,
} from '@i2e/components';
import { ChevronRightIcon, GridViewIcon } from '@in2event/icons';
import { useRouter } from 'next/navigation';

import { beautifiedDate } from '@/lib/beautify-date';
import { useTranslation } from '@/lib/i18n/client';
import { useGetEvents } from '@/services/launchpad/use-get-events';

interface EventDropdownProps {
    eventId: string;
}

// TODO: Add a loading state with a skeleton
export const EventDropdown = ({ eventId }: EventDropdownProps) => {
    const { t } = useTranslation(['menu']);
    const { events } = useGetEvents({
        config: {
            revalidateOnFocus: false,
        },
    });
    const router = useRouter();
    // Current event data
    const currentEvent = events.find((event) => event.id.toString() === eventId);
    const date = currentEvent?.dates.find((d) => d.isShowday)?.startDateTime;

    const handleEventClick = (eventId: string) => {
        router.push(`/event/${eventId}`);
    };

    return (
        <DropdownMenuRoot>
            <DropdownMenuTrigger asChild>
                <button
                    type="button"
                    className="flex h-16 w-full items-center border-b border-neutral-350 pl-6 pr-3"
                >
                    <Avatar
                        name={currentEvent?.name}
                        rounded={false}
                        src={currentEvent?.branding.logo?.url ?? ''}
                        variant={Avatar.variant.xs}
                    />
                    <div className="ml-3 flex flex-col items-start">
                        <Text className="w-[136px] truncate whitespace-nowrap text-left font-bold text-white">
                            {currentEvent?.name}
                        </Text>
                        <Text className="text-[11px] text-white opacity-50">
                            {date ? beautifiedDate(date) : null}
                        </Text>
                    </div>
                    <ChevronRightIcon className="ml-auto shrink-0 rotate-90 text-2xl text-neutral-1000" />
                </button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-[280px] py-0" alignOffset={20} align="start">
                <Text variant="overline" className="px-5 pb-2 pt-5">
                    {t('event.menu.recent.events')}
                </Text>

                {events.map((event) => (
                    <DropdownMenuItem
                        className="h-12 px-5"
                        key={event.id}
                        onClick={() => handleEventClick(event.id.toString())}
                    >
                        <div className="flex items-center gap-5">
                            <Avatar
                                name={event.name}
                                rounded={false}
                                src={event.branding.logo?.url ?? ''}
                                variant={Avatar.variant.xs}
                            />
                            {event.name}
                        </div>
                    </DropdownMenuItem>
                ))}
                <DropdownMenuSeparator className="mt-2 space-y-3 border-b-2" />
                <DropdownMenuItem
                    className="my-2 flex h-10 items-center px-5"
                    onClick={() => router.push('/event/launchpad')}
                >
                    <GridViewIcon className="size-5 text-xl" />
                    <Text className="font-medium">{t('event.menu.to.launchpad')}</Text>
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenuRoot>
    );
};
