import type { PropsWithChildren, ReactNode } from 'react';

import { useUserStore } from '@/store/useUserStore';

interface RestrictedLaunchpadProps {
    permission: string;
    fallback?: ReactNode | string;
}

// This component is meant to be used everywhere a restriction based on user permission is needed
const RestrictedLaunchpad = ({
    permission,
    fallback,
    children,
}: PropsWithChildren<RestrictedLaunchpadProps>) => {
    const { permissions } = useUserStore();

    // Creates a method that returns whether the requested permission is granted to the current user
    const isAllowedTo = (permission: string): boolean =>
        permissions?.capabilities?.includes(permission) ?? false;

    // If the user has that permission, render the children
    if (isAllowedTo(permission)) {
        return <>{children}</>;
    }

    // Otherwise, render the fallback
    return <>{fallback}</>;
};

export { RestrictedLaunchpad };
