'use client';

import { cn } from '@i2e/components';
import Link, { type LinkProps } from 'next/link';
import type { ReactNode } from 'react';

interface Props extends LinkProps {
    children: ReactNode;
    styled?: boolean;
    className?: string;
}

export const GlobalNavItem = ({ children, styled = true, className, ...props }: Props) => {
    return (
        <Link
            className={cn(className, {
                'h-8 truncate rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300':
                    styled,
            })}
            {...props}
        >
            {children}
        </Link>
    );
};
