'use client';

import { InputHint, Label, Select } from '@i2e/components';
import type { ChangeEvent } from 'react';
import toast from 'react-hot-toast/headless';
import { useTranslation } from 'react-i18next';

import { HeaderRoot, HeaderSubTitle, HeaderTitle } from '@/components/layout/header';
import { LayoutContent } from '@/components/layout/launchpad';
import i18n from '@/lib/i18n';
import { Sentry } from '@/lib/sentry';
import { useSettingsMeta } from '@/services/hooks/settings/use-settings-meta';
import { usePostSettings } from '@/services/launchpad/use-post-settings';
import { useUserStore } from '@/store/useUserStore';
import type { Language, Languages } from '@/types/settings';

interface LanguageType {
    value: Languages;
    label: string;
    code: string;
}

export const languages: LanguageType[] = [
    {
        value: 'english',
        label: 'English',
        code: 'en',
    },
    {
        value: 'dutch',
        label: 'Dutch',
        code: 'nl',
    },
    {
        value: 'german',
        label: 'German',
        code: 'de',
    },
];

const LanguagesPage = () => {
    const { t } = useTranslation(['launchpad']);
    const { permissions } = useUserStore();

    const { postSettings, error } = usePostSettings<Language>();

    // Creates a method that returns whether the requested permission is granted to the current user
    const canEdit = permissions?.capabilities?.includes('core-settings-general:edit') ?? false;

    const { meta: internalLanguage, mutate: mutateInternal } =
        useSettingsMeta('_internal_language');
    const { meta: externalLanguage, mutate: mutateExternal } =
        useSettingsMeta('_external_language');

    const handleInternalLanguageChange = async (event: ChangeEvent<HTMLSelectElement>) => {
        const result = await postSettings({
            metaKey: '_internal_language',
            metaValue: event.target.value as Languages,
        });

        if (!result.success) {
            // Error updating language
            Sentry.captureException(error, {
                extra: {
                    data: event.target.value,
                },
                tags: {
                    context: 'Language change (internal)',
                },
            });

            // Show toast
            toast.error(t('settings.language.toast.error'));
        } else {
            await mutateInternal();

            // Find the code based on the value
            const language = languages.find((lang) => lang.value === event.target.value);
            i18n.changeLanguage(language?.code ?? 'en');

            // Show toast
            toast.success(t('settings.language.toast.success'));
        }
    };

    const handleExternalLanguageChange = async (event: ChangeEvent<HTMLSelectElement>) => {
        const result = await postSettings({
            metaKey: '_external_language',
            metaValue: event.target.value as Languages,
        });

        if (!result.success) {
            // Error updating language
            Sentry.captureException(error, {
                extra: {
                    data: event.target.value,
                },
                tags: {
                    context: 'Language change (external)',
                },
            });

            // Show toast
            toast.error(t('settings.language.toast.error'));
        } else {
            await mutateExternal();

            // Show toast
            toast.success(t('settings.language.toast.success'));
        }
    };

    return (
        <LayoutContent>
            <HeaderRoot>
                <HeaderTitle>{t('settings.language.title')}</HeaderTitle>
                <HeaderSubTitle>{t('settings.language.description')}</HeaderSubTitle>
            </HeaderRoot>
            <div className="mt-8 max-w-screen-sm px-4 md:px-0">
                <div>
                    <Label>{t('settings.language.internal.languages')}</Label>
                    <Select
                        onChange={(e) => handleInternalLanguageChange(e)}
                        value={internalLanguage?.metaValue}
                        disabled={!canEdit}
                    >
                        {languages.map((language) => (
                            <option key={language.value} value={language.value}>
                                {language.label}
                            </option>
                        ))}
                    </Select>
                    <InputHint>{t('settings.language.internal.hint')}</InputHint>
                </div>
                <div className="mt-5">
                    <Label>{t('settings.language.external.languages')}</Label>
                    <Select
                        onChange={(e) => handleExternalLanguageChange(e)}
                        value={externalLanguage?.metaValue}
                        disabled={!canEdit}
                    >
                        {languages.map((language) => (
                            <option key={language.value} value={language.value}>
                                {language.label}
                            </option>
                        ))}
                    </Select>
                    <InputHint>{t('settings.language.external.hint')}</InputHint>
                </div>
            </div>
        </LayoutContent>
    );
};

export default LanguagesPage;
