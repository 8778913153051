'use client';

import { createContext, useContext } from 'react';

import { Locales, fallbackLng } from './settings';

const Context = createContext<Locales>(fallbackLng);

export const LocaleProvider = ({
    children,
    value,
}: {
    children: React.ReactNode;
    value: Locales;
}) => {
    return <Context.Provider value={value}>{children}</Context.Provider>;
};

export function useLocale() {
    return useContext(Context);
}
