'use client';

import { Tooltip, cn } from '@i2e/components';
import { ChevronLeftIcon } from '@in2event/icons';
import { MouseEvent, useState } from 'react';

import { useTranslation } from '@/lib/i18n/client';

import { SideNavContent } from './content';

interface NavState {
    isCollapsed: boolean;
    showTooltip: boolean;
}

export const SideNavDesktop = () => {
    const { t } = useTranslation(['menu']);
    const [navState, setNavState] = useState<NavState>({ isCollapsed: false, showTooltip: false });

    const handleCollapse = (e: MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setNavState((prevState) => ({ ...prevState, isCollapsed: !prevState.isCollapsed }));
    };

    const handleMouseEnter = () => {
        setNavState((prevState) => ({ ...prevState, showTooltip: true }));
    };

    const handleMouseLeave = () => {
        setNavState((prevState) => ({ ...prevState, showTooltip: false }));
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter' || e.key === ' ') {
            handleCollapse(e as unknown as MouseEvent<HTMLDivElement>);
        }
    };

    return (
        <div
            className={cn(
                'relative h-screen w-60 flex-1 appearance-none bg-neutral-200 transition-all duration-300 ease-in-out',
                {
                    'w-6 animate-out': navState.isCollapsed,
                    'w-60 animate-in': !navState.isCollapsed,
                },
            )}
            onClick={(e: MouseEvent<HTMLDivElement>) => {
                e.preventDefault();
                e.stopPropagation();
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onKeyDown={handleKeyDown} // Add onKeyDown event listener
            role="button" // Add role attribute
            tabIndex={0} // Add tabIndex attribute
        >
            {navState.showTooltip ? (
                <Tooltip
                    content={t(
                        navState.isCollapsed ? 'launchpad.menu.expand' : 'launchpad.menu.collapse',
                    )}
                    delayDuration={100}
                    side="right"
                    skipDelayDuration={0}
                >
                    <div
                        className="absolute -right-3 top-25 z-50 hidden size-6 cursor-pointer items-center justify-center rounded-full bg-neutral-700 shadow-elevation hover:bg-blue-600 lg:flex"
                        onClick={handleCollapse}
                        onKeyDown={handleKeyDown} // Add onKeyDown event listener
                        role="button" // Add role attribute
                        tabIndex={0} // Add tabIndex attribute
                    >
                        <ChevronLeftIcon
                            className={cn('size-5 text-white', {
                                'rotate-180 transition ease-linear': navState.isCollapsed,
                            })}
                        />
                    </div>
                </Tooltip>
            ) : null}

            {!navState.isCollapsed && <SideNavContent />}
        </div>
    );
};
