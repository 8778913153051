import useSWR from 'swr';
import type { SWRConfiguration } from 'swr';

import { swrConfig } from '@/lib/swr/config';
import { fetcher } from '@/lib/swr/fetcher';
import { UserApiKey } from '@/services/config/api-keys';
import type { ResponseProp } from '@/types/common';
import type { SignedInUser } from '@/types/user';

const useUserCurrent = (config?: SWRConfiguration) => {
    const { data, error, mutate, isLoading, isValidating } = useSWR<
        ResponseProp<SignedInUser>,
        Error
    >(`${UserApiKey}/current`, fetcher, config ?? swrConfig);

    const user = data?.data ?? null;

    return { user, error, mutate, isLoading, isValidating };
};

export { useUserCurrent };
