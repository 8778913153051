'use client';

import {
    Avatar,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuRoot,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
    Text,
} from '@i2e/components';
import { FaceIcon, LogoutIcon } from '@in2event/icons';
import { useRouter } from 'next/navigation';

import { useTranslation } from '@/lib/i18n/client';
import { useUserStore } from '@/store/useUserStore';

const UserNav = () => {
    const { t } = useTranslation(['launchpad']);

    const router = useRouter();

    const { userData: user } = useUserStore();

    const handleSignOut = async () => {
        await fetch('/api/auth/logout');

        // Redirect back to the login page
        router.push('/goodbye');
    };

    const handleOpenUserSettings = () => {
        router.push('/profile');
    };

    if (!user) {
        return null;
    }

    return (
        <DropdownMenuRoot>
            <DropdownMenuTrigger className="cursor-pointer">
                <Avatar
                    src={user.image || ''}
                    alt={`${user.firstName}-avatar`}
                    variant={Avatar.variant.xs}
                    name={`${user.firstName} ${user.lastName}`}
                />
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-70" align="end" forceMount>
                <div className="flex h-[70px] items-center gap-4 p-5">
                    <Avatar
                        src={user.image || ''}
                        alt={`${user.firstName}-avatar`}
                        variant={Avatar.variant.xs}
                        name={`${user.firstName} ${user.lastName}`}
                    />
                    <div className="flex flex-col space-x-0 space-y-1 truncate">
                        <Text variant="headline" className="whitespace-nowrap text-neutral-250">
                            {user.firstName} {user.lastName}
                        </Text>
                        <Text variant="caption">{user.email}</Text>
                    </div>
                </div>

                <DropdownMenuSeparator className="space-y-3 border-b-2" />

                <DropdownMenuItem
                    onSelect={handleOpenUserSettings}
                    className="flex items-center gap-3 px-3 py-2.5 text-2 font-semibold leading-4"
                >
                    <FaceIcon className="size-5" />
                    {t('user.account.settings')}
                </DropdownMenuItem>
                <DropdownMenuItem
                    onSelect={handleSignOut}
                    className="flex items-center gap-3 px-3 py-2.5 text-2 font-semibold leading-4"
                >
                    <LogoutIcon className="size-5" />
                    {t('user.logout')}
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenuRoot>
    );
};

export default UserNav;
