/**
 *
 * @param url - The URL to fetch the data from
 * @returns The data from the URL
 */

export async function fetcher<T>(input: RequestInfo, init?: RequestInit): Promise<T> {
    const res = await fetch(input, init);

    if (!res.ok) {
        const json = await res.json();

        if (json.message) {
            const error = new Error(json.message) as Error & {
                status: number;
            };
            error.status = res.status;
            throw error;
        } else {
            throw new Error('An unexpected error occurred');
        }
    }

    return res.json();
}
