'use client';

import { cleanQueryParams } from '@i2e/components';
import useSWR, { SWRConfiguration } from 'swr';

import { fetcher } from '@/lib/api/fetcher';
import { EventsResponse, runValidateEvents } from '@/schemas/event';

export const eventsSwrKey = '/api/base/events';

const headers = {
    'X-API-VERSION': 'v6',
};
export function useGetEvents({
    searchParams,
    config,
}: {
    searchParams?: Record<string, string>;
    config?: SWRConfiguration;
}) {
    // Remove undefined values from the query params
    const query = cleanQueryParams(searchParams ?? {});

    const { data, ...props } = useSWR<EventsResponse, ErrorConstructor>(
        [eventsSwrKey, query],
        ([url, queryParams]) => {
            return fetcher<EventsResponse>(
                `${url}?${new URLSearchParams(queryParams as URLSearchParams).toString()}`,
                {
                    headers,
                },
            );
        },
        config,
    );

    // Report to sentry if schema validation fails
    if (data?.data) {
        runValidateEvents(data.data);
    }

    return {
        events: data?.data ?? [],
        links: data?.links ?? null,
        meta: data?.meta ?? null,
        ...props,
    };
}
