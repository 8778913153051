import { z } from 'zod';

const mediaSchema = z
    .object({
        id: z.nullable(z.number()),
        url: z.nullable(z.string()),
    })
    .nullable();

const colorsSchema = z.object({
    backgroundColor: z.string(),
    contentBackgroundColor: z.string(),
    accentColor: z.string(),
    textColor: z.string(),
    accentTextColor: z.string(),
});

const clientBrandingSchema = z
    .object({
        organizationLogo: mediaSchema,
        headerImage: mediaSchema,
        loginImage: mediaSchema,
        colors: colorsSchema,
    })
    .optional();

const brandingSchema = z.object({
    logo: mediaSchema,
    headerImage: mediaSchema,
    colors: colorsSchema,
});

const eventBrandingSchema = z.object({
    logo: mediaSchema,
    headerImage: mediaSchema,
    colors: z.object({
        backgroundColor: z.string(),
        contentBackgroundColor: z.string(),
        accentColor: z.string(),
        textColor: z.string(),
        accentTextColor: z.string(),
    }),
});

const countrySchema = z.object({
    id: z.number(),
    countryIsoCode: z.string(),
    countryName: z.string(),
});

const companySchema = z.object({
    name: z.string(),
    address: z.string(),
    zipCode: z.string(),
    city: z.string(),
    country: countrySchema,
});

export type Media = z.infer<typeof mediaSchema>;
export type Branding = z.infer<typeof clientBrandingSchema>;
export type Country = z.infer<typeof countrySchema>;
export type Company = z.infer<typeof companySchema>;
export type EventBranding = z.infer<typeof eventBrandingSchema>;

export { mediaSchema, clientBrandingSchema, eventBrandingSchema, countrySchema, companySchema };
