'use client';

import { Skeleton, TableBody, TableCell, TableRoot, TableRow } from '@i2e/components';
import { LogoNew } from '@in2event/icons';
import { usePathname } from 'next/navigation';
import type { PropsWithChildren, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { HeaderSingleRoot } from '../header';

import Search from './search';
import UserNav from './user-nav';

import { GlobalNavItem } from '@/components/global-nav-item';
import { SideNavDesktop } from '@/components/ui/side-nav/desktop';
import { SideNavMobile } from '@/components/ui/side-nav/mobile';
import { PermissionProvider } from '@/context/permission-provider';
import { SignedInUser } from '@/types/user';

interface LayoutLaunchpadProps {
    children: ReactNode;
    signedInUser: SignedInUser;
}

export const LaunchpadLayout = ({ children, signedInUser }: LayoutLaunchpadProps) => {
    const pathname = usePathname();

    // List of single add routes
    const singleAddRoutes = ['/staff/teams/add'];

    // Check if the current pathname has a single add route in it from the list
    const isSingleAddRoute = singleAddRoutes.some((route) => pathname.includes(route));

    if (isSingleAddRoute) {
        return <div className="fixed inset-0 h-screen">{children}</div>;
    }

    return (
        <PermissionProvider signedInUser={signedInUser}>
            <div className="flex h-screen min-h-screen">
                <div className="flex-none max-lg:hidden">
                    <SideNavDesktop />
                </div>
                <div className="relative flex flex-1 flex-col overflow-y-auto">
                    <header className="divide-neutral-10 border-b-neutral-10 sticky inset-x-0 top-0 z-50 flex min-h-[64px] justify-between divide-x border-b bg-white">
                        <SideNavMobile />
                        <div className="flex flex-1 items-center px-4 lg:px-9">
                            <Search />
                            <div className="flex flex-1 items-center justify-end space-x-5">
                                <UserNav />
                            </div>
                        </div>
                    </header>
                    <main className="flex flex-1 flex-col overflow-y-auto">{children}</main>
                </div>
            </div>
        </PermissionProvider>
    );
};

export const LayoutContent = ({ children }: PropsWithChildren) => {
    return <div className="mb-9 flex-1 md:px-4 lg:px-9">{children}</div>;
};

export const LayoutSingle = ({ children }: PropsWithChildren) => {
    return <div className="bg-neutral-2100 flex flex-1 flex-col">{children}</div>;
};

export const LayoutSingleNotFound = () => {
    const { t } = useTranslation(['errors']);

    return (
        <div className="flex flex-1 flex-col items-center justify-center px-4 md:px-9">
            <div className="mb-6">
                <LogoNew height="48px" width="48px" />
            </div>
            <h1 className="mb-4 text-xl font-bold leading-6 tracking-[-0.01em]">
                {t('somethingWrong')}
            </h1>

            <div className="mb-6">
                <p className="text-center text-sm">{t('notFound')}</p>
                <p className="text-center text-sm">{t('check.url.errors')}</p>
            </div>

            <div className="inline-flex items-center gap-2">
                <GlobalNavItem href="https://helpcenter.in2event.com/">
                    {t('contact.support')}
                </GlobalNavItem>
                <GlobalNavItem href="https://in2event.com">{t('visit.homepage')}</GlobalNavItem>
            </div>
        </div>
    );
};

export const LayoutSingleSkeleton = () => {
    return (
        <LayoutSingle>
            <HeaderSingleRoot>
                <Skeleton className="mb-2 h-2.5 w-32" />
                <Skeleton className="h-2.5 w-80" />
            </HeaderSingleRoot>
            <div className="flex flex-col gap-4">
                <div className="border-neutral-1800 flex flex-row flex-nowrap space-x-6 space-y-0 border-b bg-white p-4 md:px-9">
                    <Skeleton className="h-10 w-32" />
                    <Skeleton className="h-10 w-32" />
                </div>
                <div className="bg-neutral-2100 py-10 md:px-4 lg:px-9">
                    {/* Skeleton for table header */}
                    <div className="mb-4 flex justify-between">
                        <Skeleton className="bg-neutral-20 h-8 w-80" />
                        <div className="flex space-x-2">
                            <Skeleton className="w-50 bg-neutral-20 h-8" />
                            <Skeleton className="bg-neutral-20 size-8" />
                        </div>
                    </div>

                    {/* Skeleton for table rows */}
                    <div className="border-neutral-1800 border-x-0 border-y bg-white md:rounded-md md:border">
                        <TableRoot className="md:table-fixed md:first:[&_td]:pl-5 md:last:[&_td]:pr-5 md:first:[&_th]:pl-5 md:last:[&_th]:pr-5">
                            <TableBody>
                                {[...Array(5)].map((_, i) => (
                                    <TableRow key={i}>
                                        <TableCell className="w-1/5">
                                            <Skeleton className="h-4 w-full" />
                                        </TableCell>
                                        <TableCell className="w-1/5">
                                            <Skeleton className="h-4 w-full" />
                                        </TableCell>
                                        <TableCell className="w-3/5">
                                            <Skeleton className="h-4 w-full" />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </TableRoot>
                    </div>
                </div>
            </div>
        </LayoutSingle>
    );
};

export const LaunchpadLayoutFullPage = ({ children, signedInUser }: LayoutLaunchpadProps) => {
    return <PermissionProvider signedInUser={signedInUser}>{children}</PermissionProvider>;
};
