import type { SWRConfiguration } from 'swr';
import useSWR from 'swr';

import { fetcher } from '@/lib/swr/fetcher';
import type { SystemEvent } from '@/types/event';
import type { SignedInUser } from '@/types/user';

import type { ResponseProp } from './helpers';

export const searchSwrKey = '/api/base/search?keyword=';

const swrConfig: SWRConfiguration = {
    revalidateOnMount: false,
    revalidateIfStale: false,
    revalidateOnFocus: false,
    onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Never retry on 404.
        if (error.status === 404) return;

        // Only retry up to 2 times.
        if (retryCount > 2) return;

        // Retry after 5 seconds.
        setTimeout(() => revalidate({ retryCount }), 5000);
    },
};

interface Search {
    events: SystemEvent[];
    users: SignedInUser[];
}

type SearchResponse = ResponseProp<Search>;

const useSearch = (keyword: string) => {
    const { data, error, mutate, isLoading, isValidating } = useSWR<SearchResponse, Error>(
        searchSwrKey + keyword,
        fetcher<SearchResponse>,
        swrConfig,
    );

    const results = data?.data ?? null;

    return { results, error, mutate, isLoading, isValidating };
};

export { useSearch };
