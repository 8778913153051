import { usePathname, useRouter } from 'next/navigation';
import type { ReactNode } from 'react';
import { useCallback, useEffect } from 'react';

import { permissionURLPairs } from '@/lib/constants';
import i18n from '@/lib/i18n';
import { languages } from '@/page/settings/languages';
import { useSettingsMeta } from '@/services/hooks/settings/use-settings-meta';
import { useUserStore } from '@/store/useUserStore';
import { SignedInUser } from '@/types/user';

const hasPermission = (capabilitySlug: string, permissions?: string[]): boolean =>
    Boolean(permissions?.includes(capabilitySlug));

const PermissionProvider = ({
    children,
    signedInUser,
}: {
    children: ReactNode;
    signedInUser: SignedInUser;
}) => {
    const router = useRouter();
    const pathname = usePathname();
    const { setUserDataAndPermissions } = useUserStore();
    const { meta: internalLanguage, isLoading: isLoadingInternalLanguage } =
        useSettingsMeta('_internal_language');

    const abortRouteChange = useCallback(() => {
        router.push('/403');
    }, [router]);

    const matchingCapabilitySlug = useCallback(
        (url: string): string =>
            permissionURLPairs.find((pair) => url.startsWith(pair.pathname))?.permission ?? '',
        [],
    );

    useEffect(() => {
        // Find the code based on the value
        if (!isLoadingInternalLanguage) {
            const language = languages.find(
                (lang) => lang.value === (internalLanguage?.metaValue ?? 'english'),
            );

            i18n.changeLanguage(language?.code ?? 'en');
        }
    }, [internalLanguage?.metaValue, isLoadingInternalLanguage]);

    useEffect(() => {
        if (pathname) {
            setUserDataAndPermissions(signedInUser);
            const capabilitySlug = matchingCapabilitySlug(pathname);
            if (capabilitySlug && !hasPermission(capabilitySlug, signedInUser.capabilities)) {
                abortRouteChange();
            }
        }
    }, [
        setUserDataAndPermissions,
        matchingCapabilitySlug,
        pathname,
        abortRouteChange,
        signedInUser,
    ]);

    return <>{children}</>;
};

export { PermissionProvider };
