'use client';

import {
    Command,
    CommandDialog,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
    CommandLoading,
    cn,
    useDebouncedValue,
} from '@i2e/components';
import { ArrowBackwardIcon, FaceIcon, SearchIcon, TuneIcon } from '@in2event/icons';
import { useRouter } from 'next/navigation';
import { useCallback, useEffect, useRef, useState } from 'react';
import type { ComponentPropsWithRef, KeyboardEvent as ReactKeyboardEvent } from 'react';

import { useTranslation } from '@/lib/i18n/client';
import { useSearch } from '@/services/hooks/use-search';
import { useUserCurrent } from '@/services/hooks/users/use-user-current';
import type { SystemEvent } from '@/types/event';
import type { SignedInUser } from '@/types/user';

const Search = () => {
    const router = useRouter();

    const { t } = useTranslation(['launchpad']);

    const ref = useRef<HTMLDivElement | null>(null);

    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');

    const [pages, setPages] = useState<string[]>(['home']);
    const activePage = pages[pages.length - 1];
    const isHome = activePage === 'home';

    const search = useDebouncedValue(inputValue, 300);

    const popPage = useCallback(() => {
        setPages((pages) => {
            const newPages = [...pages];
            newPages.splice(-1, 1);

            return newPages;
        });
    }, []);

    const { user } = useUserCurrent();
    const { results, isLoading } = useSearch(search);

    const onKeyDown = useCallback(
        (e: ReactKeyboardEvent) => {
            if (e.key === 'Enter') {
                bounce();
            }

            if (isHome || inputValue.length) {
                return;
            }

            if (e.key === 'Backspace') {
                e.preventDefault();
                popPage();
            }
        },
        [inputValue.length, isHome, popPage],
    );

    function bounce() {
        if (ref.current) {
            ref.current.style.transform = 'scale(0.96)';
            setTimeout(() => {
                if (ref.current) {
                    ref.current.style.transform = '';
                }
            }, 100);

            setInputValue('');
        }
    }

    const handleSearch = () => {
        setOpen(true);
    };

    const handleRouteChange = (route: string) => {
        router.push(route);

        setOpen(false);
    };

    useEffect(() => {
        const down = (e: KeyboardEvent) => {
            if (e.key === 'i' && e.metaKey) {
                setOpen((open) => !open);
            }
        };

        document.addEventListener('keydown', down);

        return () => document.removeEventListener('keydown', down);
    }, []);

    if (user?.capabilities.length === 0) {
        return null;
    }

    return (
        <>
            <button type="button" className="flex cursor-text items-center" onClick={handleSearch}>
                <SearchIcon className="mr-2 size-6 shrink-0 opacity-50" />
                <p className="text-sm font-medium text-neutral-50">
                    {t('topbar.search.placeholder')}
                </p>
            </button>

            <CommandDialog open={open} onOpenChange={setOpen}>
                <Command
                    ref={ref}
                    onKeyDown={onKeyDown}
                    shouldFilter={false}
                    className={cn(
                        '[&_[cmdk-group-heading]]:mt-2 [&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:font-semibold [&_[cmdk-group-heading]]:text-neutral-250 [&_[cmdk-group]:not([hidden])_~[cmdk-group]]:pt-0 [&_[cmdk-group]]:px-2 [&_[cmdk-input-wrapper]_svg]:size-5 [&_[cmdk-input]]:h-12 [&_[cmdk-item]]:px-2 [&_[cmdk-item]]:py-3 [&_[cmdk-item]_svg]:size-5 [&_[cmdk-loading]]:px-2',
                    )}
                >
                    <CommandInput
                        placeholder={t('topbar.search.placeholder')}
                        onValueChange={(value) => {
                            setInputValue(value);
                        }}
                        disabled={activePage === 'home'}
                    />

                    <div className="m-2 flex gap-2">
                        {pages.map((p) => (
                            <div
                                key={p}
                                className="w-fit rounded bg-neutral-30 px-2 py-1 text-xs font-medium uppercase text-white"
                            >
                                {p}
                            </div>
                        ))}
                    </div>

                    <CommandList>
                        {isLoading ? (
                            <CommandLoading>
                                <div className="my-2 flex cursor-default select-none items-center rounded-sm px-2 py-1.5">
                                    <span className="text-sm">{t('topbar.search.loading')}</span>
                                </div>
                            </CommandLoading>
                        ) : inputValue.length ? (
                            <CommandEmpty>
                                <div className="my-2 flex cursor-default select-none items-center rounded-sm px-2 py-1.5">
                                    <span className="text-sm">{t('topbar.search.no.results')}</span>
                                </div>
                            </CommandEmpty>
                        ) : null}
                        {activePage === 'home' && (
                            <Home
                                searchUsers={() => setPages([...pages, 'users'])}
                                searchEvents={() => setPages([...pages, 'events'])}
                            />
                        )}
                        {activePage === 'users' && results?.users ? (
                            <Users
                                users={results.users}
                                goBackHome={() => setPages(['home'])}
                                onSelectUser={handleRouteChange}
                            />
                        ) : null}
                        {activePage === 'events' && results?.events ? (
                            <Events
                                events={results.events}
                                goBackHome={() => setPages(['home'])}
                                onSelectEvent={handleRouteChange}
                            />
                        ) : null}
                    </CommandList>
                </Command>
            </CommandDialog>
        </>
    );
};

interface HomeProps {
    searchUsers: () => void;
    searchEvents: () => void;
}

const Home = ({ searchUsers, searchEvents }: HomeProps) => {
    const { t } = useTranslation(['launchpad']);

    return (
        <>
            <CommandGroup heading={t('topbar.search.users.title')}>
                <Item
                    onSelect={() => {
                        searchUsers();
                    }}
                >
                    {t('topbar.search.users')}
                </Item>
            </CommandGroup>
            <CommandGroup heading={t('topbar.search.events.title')}>
                <Item
                    onSelect={() => {
                        searchEvents();
                    }}
                >
                    {t('topbar.search.events')}
                </Item>
            </CommandGroup>
        </>
    );
};

const Users = ({
    users,
    goBackHome,
    onSelectUser,
}: {
    users: SignedInUser[];
    goBackHome: () => void;
    onSelectUser: (value: string) => void;
}) => {
    const { t } = useTranslation(['launchpad']);

    return (
        <>
            <Item
                onSelect={() => {
                    goBackHome();
                }}
            >
                <ArrowBackwardIcon className="mr-2 size-4" />
                {t('topbar.search.back.home')}
            </Item>

            {users.map((user) => (
                <Item key={user.id} onSelect={() => onSelectUser(`/team/people/${user.id}`)}>
                    <div className="flex flex-row">
                        <span className="hidden">{user.id}</span>
                        <FaceIcon className="mr-2 size-4" />
                        <span className="grow">
                            <span>
                                {user.firstName} {user.lastName}
                            </span>
                            <span className="block text-gray-400">{user.email}</span>
                        </span>
                    </div>
                </Item>
            ))}
        </>
    );
};

const Events = ({
    events,
    goBackHome,
    onSelectEvent,
}: {
    events: SystemEvent[];
    goBackHome: () => void;
    onSelectEvent: (value: string) => void;
}) => {
    const { t } = useTranslation(['launchpad']);

    return (
        <>
            <Item
                onSelect={() => {
                    goBackHome();
                }}
            >
                <ArrowBackwardIcon className="mr-2 size-4" />
                {t('topbar.search.back.home')}
            </Item>

            {events.map((event) => (
                <Item key={event.id} onSelect={() => onSelectEvent(`/event/${event.id}`)}>
                    <div className="flex flex-row">
                        <span className="hidden">{event.id}</span>
                        <TuneIcon className="mr-2 size-4" />
                        <span>{event.eventName}</span>
                    </div>
                </Item>
            ))}
        </>
    );
};

interface ItemProps extends ComponentPropsWithRef<typeof CommandItem> {
    shortcut?: string;
}

const Item = ({ children, shortcut, onSelect }: ItemProps) => {
    return (
        <CommandItem onSelect={onSelect}>
            {children}
            {shortcut ? (
                <div className="ml-auto flex gap-1">
                    {shortcut.split(' ').map((key) => {
                        return (
                            <kbd
                                key={key}
                                className="w-fit rounded bg-neutral-60 px-2 py-1 text-xs font-medium uppercase text-white"
                            >
                                {key}
                            </kbd>
                        );
                    })}
                </div>
            ) : null}
        </CommandItem>
    );
};

export default Search;
