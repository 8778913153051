import type { InitOptions } from 'i18next';

export const fallbackLng = 'en';
export const languages = [fallbackLng, 'nl', 'de', 'fr'] as const;
export type Locales = (typeof languages)[number];
export const defaultNS = 'common';
export const cookieName = 'i18next';

export function getOptions(lng = fallbackLng, ns = defaultNS): InitOptions {
    return {
        // debug: true,
        supportedLngs: languages,
        fallbackLng: lng,
        lng,
        fallbackNS: defaultNS,
        defaultNS,
        ns,
    };
}
