import { MenuShape } from '@/types/menu';

export const staticStaffMenu = (eventId: string): MenuShape[] => [
    {
        icon: 'ArtistIcon',
        label: 'launchpad.menu.Artists',
        uriPath: '/program/artists',
        legacy: 0,
        menu: [
            {
                icon: false,
                label: 'launchpad.menu.allArtists',
                uriPath: '/program/artists',
                legacy: 0,
                menu: [],
            },
        ],
    },
    {
        icon: 'StaffManagementIcon',
        label: 'launchpad.menu.Staff',
        uriPath: `/event/${eventId}/staff`,
        legacy: 0,
        menu: [
            {
                icon: false,
                label: 'launchpad.menu.AllStaff',
                uriPath: `/event/${eventId}/staff`,
                legacy: 0,
                menu: [],
            },
            {
                icon: false,
                label: 'event.menu.StaffTeams',
                uriPath: `/event/${eventId}/staff/teams`,
                legacy: 0,
                menu: [],
            },
            {
                icon: false,
                label: 'event.menu.StaffPlanning',
                uriPath: `/event/${eventId}/staff/planning`,
                legacy: 0,
                menu: [],
            },
        ],
    },
];
