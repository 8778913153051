import useSWR from 'swr';

import { fetcher } from '@/lib/api/fetcher';
import { isBrowser, isDevelopment } from '@/lib/constants';
import { swrConfig } from '@/lib/swr/config';
import type { ClientResponse } from '@/types/client';
import { getEnv } from 'env';

export const clientsSwrKey = '/api/base/client?subdomain=';

const getSubdomain = () => {
    const { REFERER } = getEnv();

    let host = '';
    // Remove the protocol from the referer
    const ref = REFERER ? REFERER.substring(8) : undefined;

    if (isBrowser) {
        host = isDevelopment && ref ? ref : window.location.host;
    }

    return host.split('.')[0];
};

const headers = {
    'X-API-VERSION': 'v6',
};

const useClients = () => {
    const subdomain = getSubdomain();

    const { data, error, mutate, isLoading, isValidating } = useSWR<ClientResponse, Error>(
        clientsSwrKey + subdomain,
        () => fetcher<ClientResponse>(clientsSwrKey + subdomain, { headers }),
        swrConfig,
    );

    const client = data?.data ?? null;

    return { client, error, mutate, isLoading, isValidating };
};

export { useClients };
