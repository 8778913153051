import { create } from 'zustand';

import type { SignedInUserData, SignedInUserPermissions } from '@/types/cookies';
import type { SignedInUser } from '@/types/user';

interface UserState {
    userData?: SignedInUserData;
    permissions?: SignedInUserPermissions;
    setUserDataAndPermissions: (data: SignedInUser) => void;
}

const useUserStore = create<UserState>((set) => ({
    permissions: {
        capabilities: [],
        eventCapabilities: [],
    },
    setUserDataAndPermissions: (data) => {
        const { capabilities, eventCapabilities, ...userData } = data;

        set({
            userData,
            permissions: { capabilities, eventCapabilities },
        });
    },
}));

export { useUserStore };
