'use client';

import { MenuIcon } from '@in2event/icons';
import * as SheetPrimitive from '@radix-ui/react-dialog';

import { SideNavContent } from './content';

export const SideNavMobile = () => {
    return (
        <SheetPrimitive.Root>
            <SheetPrimitive.Trigger className="flex size-16 shrink-0 items-center justify-center lg:hidden">
                <MenuIcon className="size-6 fill-neutral-250" />
            </SheetPrimitive.Trigger>
            <SheetPrimitive.Portal>
                <SheetPrimitive.Overlay className="fixed inset-0 z-50 bg-neutral-40" />
                <SheetPrimitive.Content className="fixed inset-0 z-50 w-60 bg-neutral-200 transition ease-in-out focus:outline-none data-[state=closed]:duration-300 data-[state=open]:duration-500 data-[state=open]:animate-in data-[state=closed]:animate-out">
                    <SideNavContent />
                </SheetPrimitive.Content>
            </SheetPrimitive.Portal>
        </SheetPrimitive.Root>
    );
};
