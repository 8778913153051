import { z } from 'zod';
import { eventBrandingSchema } from './branding';

const eventDateSchema = z.object({
    id: z.number(),
    name: z.nullable(z.string()),
    startDateTime: z.string(),
    endDateTime: z.string(),
    isShowday: z.boolean(),
    selected: z.boolean(),
    disabled: z.boolean().optional(), // Todo: Remove optional
});

const eventLocationSchema = z.object({
    id: z.number(),
    type: z.string(),
    shortcode: z.nullable(z.string()),
    name: z.string(),
    address: z.string(),
    zipcode: z.string(),
    city: z.string(),
    country: z
        .object({
            id: z.number(),
            countryIsoCode: z.string(),
            countryName: z.string(),
        })
        .nullable(),
    verified: z.boolean(),
});

const eventSchema = z.object({
    id: z.number(),
    name: z.string(),
    edition: z.string(),
    branding: eventBrandingSchema,
    dates: z.array(eventDateSchema),
    location: eventLocationSchema.nullable(),
    status: z.number(),
});

export type Event = z.infer<typeof eventSchema>;
export type EventDate = z.infer<typeof eventDateSchema>;
export type EventLocation = z.infer<typeof eventLocationSchema>;

/**
 *
 * @param dates unknown[]
 * @returns EventDate[]
 * @description Validate event dates
 * @example validateEventDates(dates)
 * Optionally you can do something if the validation fails
 */
export const validateEventDates = (dates: unknown[]): { dates: EventDate[]; isError: boolean } => {
    const parsed = z.array(eventDateSchema).safeParse(dates);

    if (!parsed.success) {
        return { dates: [], isError: true };
    }

    return { dates: parsed.data, isError: false };
};

export { eventSchema, eventDateSchema, eventLocationSchema };
