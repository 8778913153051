import { useState } from 'react';

export const usePostSettings = <T>(headers?: Record<string, string>) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const postSettings = async (payload: T) => {
        setIsLoading(true);
        setError(null);

        try {
            const response = await fetch('/api/base/settings', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    ...headers,
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error('Failed to update language settings');
            }

            return { success: true };
        } catch (err: unknown) {
            setError((err as Error).message);

            return { success: false };
        } finally {
            setIsLoading(false);
        }
    };

    return { postSettings, isLoading, error };
};
