type RequestInfo = string | Request;

async function fetcher<T>(url: URL | RequestInfo): Promise<T> {
    const res = await fetch(url);

    // If the status code is not in the range 200-299,
    // we still try to parse and throw it.
    if (!res.ok) {
        const error = new Error('An error occurred while fetching the data.') as Error & {
            info: Record<string, unknown>;
            status: number;
        };
        // Attach extra info to the error object.
        error.info = (await res.json()) as Record<string, unknown>;

        error.status = res.status;

        throw error;
    }

    return res.json() as Promise<T>;
}

async function multiArgumentFetcher<T>(
    args: [URL | RequestInfo, Record<string, string>],
): Promise<T> {
    const [key, queryParams] = args;

    let url = typeof key === 'string' ? key : '';
    if (Object.keys(queryParams).length > 0) {
        url += `?${new URLSearchParams(queryParams).toString()}`;
    }

    const res = await fetch(url);

    // If the status code is not in the range 200-299,
    // we still try to parse and throw it.
    if (!res.ok) {
        const error = new Error('An error occurred while fetching the data.') as Error & {
            info: Record<string, unknown>;
            status: number;
        };
        // Attach extra info to the error object.
        error.info = (await res.json()) as Record<string, unknown>;
        error.status = res.status;

        throw error;
    }

    return res.json() as Promise<T>;
}

export { fetcher, multiArgumentFetcher };
