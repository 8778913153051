// This is how the language are coming from the system
export type Lang = 'english' | 'dutch' | 'german';

const validLangs: Lang[] = ['english', 'dutch', 'german'];

export const isLang = (lang: string | undefined): lang is Lang => {
    return validLangs.includes(lang as Lang);
};

// We map them to the i18n language codes
export const mapLangToi18n = (lang: Lang) => {
    switch (lang) {
        case 'english':
            return 'en';
        case 'dutch':
            return 'nl';
        case 'german':
            return 'de';
        default:
            return 'en';
    }
};

export const mapLangToi18nReverse = (lang: string) => {
    switch (lang) {
        case 'en':
            return 'english';
        case 'nl':
            return 'dutch';
        case 'de':
            return 'german';
        default:
            return 'english';
    }
};
