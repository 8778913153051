'use client';

import { AvatarImage, AvatarRoot } from '@i2e/components';
import { ArrowCircleLeftIcon } from '@in2event/icons';
import Link from 'next/link';
import { useParams, usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';

import { useTranslation } from '@/lib/i18n/client';
import { useMenus } from '@/services/hooks/menu/use-menus';
import { useClients } from '@/services/hooks/use-clients';
import type { MenuShape } from '@/types/menu';

import { ActiveMenuItem } from './active-menu-item';
import { EventDropdown } from './event-dropdown';
import { MenuItem } from './menu-item';

export const SideNavContent = () => {
    const { t } = useTranslation(['menu']);
    const pathname = usePathname();
    const params = useParams<{ eventId: string }>();

    // Data
    const { menu, isLoading } = useMenus(params.eventId);
    const { client } = useClients();

    const [activeRootIndex, setActiveRootIndex] = useState<number>();

    useEffect(() => {
        if (isLoading) {
            return;
        }

        const findActiveRootIndex = (menuItems: MenuShape[], path: string) => {
            for (let i = 0; i < menuItems.length; i++) {
                const menuItem = menuItems[i];

                // Exact match or valid nested path check
                if (path === menuItem?.uriPath || path.startsWith(`${menuItem?.uriPath}/`)) {
                    return i;
                }

                if (menuItem?.menu.length) {
                    const subIndex = findActiveRootIndex(menuItem.menu, path);
                    if (subIndex !== -1) {
                        return i;
                    }
                }
            }

            return -1;
        };

        const rootIndex = pathname ? findActiveRootIndex(menu, pathname) : -1;
        setActiveRootIndex(rootIndex);
    }, [isLoading, menu, pathname]);

    const activeRootMenuItem = activeRootIndex ? menu[activeRootIndex] : null;
    const showActiveMenuItem = activeRootMenuItem && activeRootMenuItem.menu.length > 0;

    // TODO: clean this soup
    return (
        <nav>
            {params.eventId ? (
                <EventDropdown eventId={params.eventId} />
            ) : (
                <div className="flex h-16 shrink-0 items-center space-x-3 border-b border-neutral-350 px-6 py-4.5">
                    <AvatarRoot>
                        <AvatarImage
                            alt="Organizer logo"
                            src="/images/logo.png?height=40&width=40"
                        />
                    </AvatarRoot>
                    <span className="text-base font-semibold leading-5 text-white">
                        {client?.company.companyName ?? 'In2Event'}
                    </span>
                </div>
            )}

            {showActiveMenuItem ? (
                <Link
                    className="flex h-12 items-center border-b border-neutral-350 bg-neutral-200 text-2 font-medium leading-8 text-neutral-1250"
                    href={params.eventId ? `/event/${params.eventId}` : '/event/launchpad'}
                >
                    <ArrowCircleLeftIcon className="ml-[26px] mr-[14px] size-6 fill-neutral-2250 leading-8" />
                    {params.eventId ? t('event.menu.to.dashboard') : t('launchpad.menu.backTo')}
                </Link>
            ) : null}

            {showActiveMenuItem ? (
                <ActiveMenuItem menuItem={activeRootMenuItem} />
            ) : (
                <ul className="animate-enter-from-left space-y-base px-4 py-6">
                    {menu.map((menuItem) => (
                        <MenuItem key={menuItem.label} menuItem={menuItem} />
                    ))}
                </ul>
            )}
        </nav>
    );
};
