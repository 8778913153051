import { eventDateSchema, eventSchema } from '@i2e/components';
import { z } from 'zod';

import { Pagination } from './pagination';

import { Sentry } from '@/lib/sentry';
import { ResponseProp } from '@/types/common';

const eventSegmentSchema = z.object({
    id: z.number(),
    name: z.string(),
});

const eventCrowdTypeSchema = z.object({
    id: z.number(),
    name: z.string(),
    segments: z.array(eventSegmentSchema),
});

const eventRoleSchema = z.object({
    id: z.number(),
    name: z.string(),
    dates: eventDateSchema.array(),
});

const eventItemTypeSchema = z.enum(['item', 'access_zone_role']);

const eventItemSchema = z.object({
    id: z.number(),
    type: eventItemTypeSchema,
    dates: eventDateSchema.array(),
    name: z.string(),
    price: z.nullable(z.number()),
    maxAmount: z.number(),
    category: z
        .object({
            id: z.string(),
            name: z.string(),
        })
        .nullable(),
    sku: z.nullable(z.string()),
    costPrice: z.nullable(z.number()),
});

const eventEntranceSchema = z.object({
    id: z.number(),
    name: z.string(),
});

const eventAccessZoneSchema = z.object({
    id: z.number(),
    name: z.string(),
});

const calculateDate = z.object({
    id: z.number(),
    date: z.string(),
    showday: z.boolean(),
    newDate: z.string(),
});

export const runValidateEvents = (events: unknown, context?: string) => {
    const validateEvents = z.array(eventSchema).safeParse(events);

    if (!validateEvents.success) {
        Sentry.captureException(validateEvents.error, {
            extra: {
                data: events,
            },
            tags: {
                context: context ?? 'Event schema validation failed',
            },
        });
        return validateEvents.error;
    }

    return null;
};

export const runValidateEventSegments = (segments: unknown, context?: string) => {
    const validateSegments = z.array(eventSegmentSchema).safeParse(segments);

    if (!validateSegments.success) {
        Sentry.captureException(validateSegments.error, {
            extra: {
                data: segments,
            },
            tags: {
                context: context ?? 'Event segments schema validation failed',
            },
        });
        return validateSegments.error;
    }

    return null;
};

export const runValidateEventItems = (items: unknown, context?: string) => {
    const validateItems = z.array(eventItemSchema).safeParse(items);

    if (!validateItems.success) {
        Sentry.captureException(validateItems.error, {
            extra: {
                data: items,
            },
            tags: {
                context: context ?? 'Event items schema validation failed',
            },
        });
        return validateItems.error;
    }

    return null;
};

export const runValidateEventRoles = (roles: unknown, context?: string) => {
    const validateRoles = z.array(eventRoleSchema).safeParse(roles);

    if (!validateRoles.success) {
        Sentry.captureException(validateRoles.error, {
            extra: {
                data: roles,
            },
            tags: {
                context: context ?? 'Event roles schema validation failed',
            },
        });
        return validateRoles.error;
    }

    return null;
};

export const runValidateEventCrowdTypes = (crowdTypes: unknown, context?: string) => {
    const validateCrowdTypes = z.array(eventCrowdTypeSchema).safeParse(crowdTypes);

    if (!validateCrowdTypes.success) {
        Sentry.captureException(validateCrowdTypes.error, {
            extra: {
                data: crowdTypes,
            },
            tags: {
                context: context ?? 'Event crowd types schema validation failed',
            },
        });
        return validateCrowdTypes.error;
    }

    return null;
};

export const runValidateEventEntrances = (entrances: unknown, context?: string) => {
    const validateEntrances = z.array(eventEntranceSchema).safeParse(entrances);

    if (!validateEntrances.success) {
        Sentry.captureException(validateEntrances.error, {
            extra: {
                data: entrances,
            },
            tags: {
                context: context ?? 'Event entrances schema validation failed',
            },
        });
        return validateEntrances.error;
    }

    return null;
};

export const runValidateEventAccessZones = (accessZones: unknown, context?: string) => {
    const validateAccessZones = z.array(eventAccessZoneSchema).safeParse(accessZones);

    if (!validateAccessZones.success) {
        Sentry.captureException(validateAccessZones.error, {
            extra: {
                data: accessZones,
            },
            tags: {
                context: context ?? 'Event access zones schema validation failed',
            },
        });
        return validateAccessZones.error;
    }

    return null;
};

export type Event = z.infer<typeof eventSchema>;
export type EventsResponse = ResponseProp<Event[]> & Pagination;
export type EventByIdResponse = ResponseProp<Event>;
export type EventSegment = z.infer<typeof eventSegmentSchema>;
export type EventSegmentsResponse = ResponseProp<EventSegment[]>;
export type EventItem = z.infer<typeof eventItemSchema>;
export type EventItemsResponse = ResponseProp<EventItem[]>;
export type EventRole = z.infer<typeof eventRoleSchema>;
export type EventRolesResponse = ResponseProp<EventRole[]>;
export type EventItemType = z.infer<typeof eventItemTypeSchema>;
export type CalculateDate = z.infer<typeof calculateDate>;
export type CalculateDatesResponse = ResponseProp<{
    dates: CalculateDate[];
}>;
export type EventCrowdType = z.infer<typeof eventCrowdTypeSchema>;
export type EventCrowdTypesResponse = ResponseProp<EventCrowdType[]>;
export type DuplicatedEventDate = Record<string, number>;
export type DuplicatedEventDatesResponse = ResponseProp<DuplicatedEventDate[]>;
export type EventEntrance = z.infer<typeof eventEntranceSchema>;
export type EventEntrancesResponse = ResponseProp<EventEntrance[]>;
export type EventAccessZone = z.infer<typeof eventAccessZoneSchema>;
export type EventAccessZonesResponse = ResponseProp<EventAccessZone[]>;

export { eventSchema, eventSegmentSchema, eventRoleSchema, eventItemSchema, eventItemTypeSchema };
