import type { SWRConfiguration } from 'swr';

const swrConfig: SWRConfiguration = {
    revalidateOnMount: true,
    revalidateIfStale: false,
    revalidateOnFocus: false,
    onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Never retry on 404.
        if (error.status === 404) return;

        // Only retry up to 2 times.
        if (retryCount > 2) return;

        // Retry after 5 seconds.
        setTimeout(() => revalidate({ retryCount }), 5000);
    },
};

export { swrConfig };
