export function beautifiedDate(input: string | number): string {
    const date = new Date(input);

    return `${date.toLocaleDateString('en-NL', {
        day: 'numeric',
        month: 'short',
    })} '${date.toLocaleDateString('en-NL', {
        year: '2-digit',
    })}`;
}
