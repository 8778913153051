import useSWR from 'swr';
import type { Key } from 'swr';

import { fetcher } from '@/lib/api/fetcher';
import { swrConfig } from '@/lib/swr/config';
import type { ResponseProp } from '@/types/common';
import type { MenuShape } from '@/types/menu';

import { staticStaffMenu } from './static-staff-menu';

const ApiKey: Key = '/api/base/menu';

const headers = {
    'X-API-VERSION': 'v6',
};

const useMenus = (eventId: string | null = null) => {
    const key = eventId ? `/api/base/events/${eventId}/menu` : ApiKey;

    const { data, error, mutate, isLoading } = useSWR<ResponseProp<MenuShape[]>, Error>(
        key,
        (url: string) =>
            fetcher<ResponseProp<MenuShape[]>>(url, {
                ...(eventId ? { headers } : {}),
            }),
        swrConfig,
    );

    const menuResponse = data?.data ?? [];
    // TODO: when Sjoerd fixes the menu, remove the static menu
    const menu = eventId ? staticStaffMenu(eventId) : menuResponse;

    return { menu, error, mutate, isLoading };
};

export { useMenus };
