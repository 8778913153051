import { NextRequest } from 'next/server';

export const COMPANY_NAME = 'In2event B.V.';
export const API_BACKEND = process.env.API_BACKEND || 'https://localhost:3000';

export const GOOGLE_MAP_API_KEY = 'AIzaSyBQ87q7H6FO2Olv3EjGrjUBT_4tRIYcXqk';

/**
 * This is needed so that production knows who the client is.
 * For local development, we can just use the demo.stg.in2event.com
 */
export const referer = (req: NextRequest) =>
    process.env.NODE_ENV === 'production'
        ? (req.headers.get('referer') ?? '')
        : 'https://demo.dev.in2event.com';

export const DEFAULT_HEADERS = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
};

export const isProduction = process.env.NODE_ENV === 'production';
export const isDevelopment = process.env.NODE_ENV === 'development';
export const isBrowser = typeof window !== 'undefined';

export const Envs = {
    PAGE_SECRET: process.env.NEXT_PUBLIC_PAGE_SECRET,
    NODE_ENV: process.env.NODE_ENV,
};

export const SUPPORT_URL = 'mailto:support@in2event.com';

export const permissionURLPairs = [
    {
        permission: 'core-settings-event:add',
        pathname: '/event/create',
    },
    {
        permission: 'program-launchpad-artist:view',
        pathname: '/program/artists',
    },
    {
        permission: 'program-launchpad-artist:view',
        pathname: '/program/artists/[id]',
    },
    {
        permission: 'program-launchpad-artist_performer:view',
        pathname: '/program/artists/[id]/performers',
    },
    {
        permission: 'program-launchpad-artist_contact:view',
        pathname: '/program/artists/[id]/contacts',
    },
    {
        permission: 'program-launchpad-artist_rider:view',
        pathname: '/program/artists/[id]/riders',
    },
    {
        permission: 'core-database-companies:view',
        pathname: '/databases/companies',
    },
    {
        permission: 'core-database-companies:view',
        pathname: '/databases/companies/[id]',
    },
    {
        permission: 'core-database-companies:view',
        pathname: '/databases/companies/[id]/contacts',
    },
    {
        permission: 'core-database-companies:view',
        pathname: '/databases/companies/[id]/staff',
    },
    {
        permission: 'core-team-people:view',
        pathname: '/team/people/[id]',
    },
    {
        permission: 'core-team-people:view',
        pathname: '/team/people/[id]/events',
    },
    {
        permission: 'core-team-roles_capabilities:view',
        pathname: '/team/roles_capabilities/view/[id]',
    },
    {
        permission: 'core-settings-general:view',
        pathname: '/settings/languages',
    },
    {
        permission: 'core-settings-general:view',
        pathname: '/settings/print_templates',
    },
    {
        permission: 'core-settings-defaults:view',
        pathname: '/settings/crowd_types',
    },
    {
        permission: 'core-settings-defaults:view',
        pathname: '/settings/user_meta_fields',
    },
    {
        permission: 'core-settings-defaults:view',
        pathname: '/settings/accreditations',
    },
    {
        permission: 'core-settings-defaults:view',
        pathname: '/settings/accommodations',
    },
    {
        permission: 'core-settings-financial:view',
        pathname: '/settings/financial_categories',
    },
    {
        permission: 'core-settings-defaults:view',
        pathname: '/program/settings/tasks',
    },
    {
        permission: 'core-settings-defaults:view',
        pathname: '/program/settings/rider_items',
    },
    {
        permission: 'core-settings-defaults:view',
        pathname: '/program/settings/contact_types',
    },
];

export const isAccessZone = 'access_zone_role';

export enum HandsontableColors {
    PrimaryBackground = '#E0EBFF',
    PrimaryText = '#0057FF',
    SecondaryBackground = '#F6F6F6',
    Border = '#C5C5C5',
}
