'use client';

import { MenuIcon, MenuLink } from '@i2e/components';
import { ArrowCircleRightIcon } from '@in2event/icons';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

import { useTranslation } from '@/lib/i18n/client';
import type { MenuShape } from '@/types/menu';

interface MenuItemProps {
    menuItem: MenuShape;
}

export const MenuItem = ({ menuItem }: MenuItemProps) => {
    const pathname = usePathname();
    const { t } = useTranslation(['menu']);

    const isActive = pathname === menuItem.uriPath;
    const hasSubmenu = menuItem.menu.length > 0;
    const href = menuItem.menu[0]?.uriPath || menuItem.uriPath;
    // Check if href starts with a slash otherwise add it
    const linkHref = href.startsWith('/') ? href : `/${href}`;

    return (
        <li>
            <MenuLink asChild isActive={isActive}>
                <Link href={linkHref}>
                    <div className="flex items-center gap-4">
                        {menuItem.icon ? (
                            <MenuIcon icon={menuItem.icon} isActive={isActive} />
                        ) : null}
                        {t(menuItem.label)}
                    </div>
                    {hasSubmenu ? (
                        <ArrowCircleRightIcon className="invisible size-5 fill-neutral-2250 group-hover:visible" />
                    ) : null}
                </Link>
            </MenuLink>
        </li>
    );
};
