'use client';

import { MenuItemContent, MenuItemRoot, MenuItemTrigger, MenuLink } from '@i2e/components';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

import { useTranslation } from '@/lib/i18n/client';
import type { MenuShape } from '@/types/menu';

interface ActiveMenuItemProps {
    menuItem: MenuShape;
}

function getActiveSubmenuItem(
    pathname: string | null,
    submenuItems: { uriPath: string; label: string }[],
) {
    // Map each submenuItem with its segment count
    const itemsWithDepth = submenuItems.map((item) => ({
        ...item,
        depth: item.uriPath.split('/').length,
    }));

    // Sort items by depth in descending order to prioritize deeper (more specific) paths
    itemsWithDepth.sort((a, b) => b.depth - a.depth);

    // Filter to find items that match the current path and have the highest depth
    const deepestMatchingItems = itemsWithDepth.filter(
        (item) => pathname === item.uriPath || pathname?.startsWith(`${item.uriPath}/`),
    );

    // If there are multiple items with the same depth, prioritize the one that matches the current path exactly
    // If none matches exactly, the first item in the sorted list is returned
    const activeItem =
        deepestMatchingItems.find((item) => pathname === item.uriPath) ?? deepestMatchingItems[0];

    return activeItem;
}

export const ActiveMenuItem = ({ menuItem }: ActiveMenuItemProps) => {
    const pathname = usePathname();
    const { t } = useTranslation(['menu']);
    const activeSubmenuItem = getActiveSubmenuItem(pathname, menuItem.menu);

    const renderSubmenuItems = (submenuItems: MenuShape[]) => {
        return submenuItems.map((item) => (
            <li key={item.label}>
                <MenuLink className="h-8 pl-6 text-xs" isActive={pathname === item.uriPath} asChild>
                    <Link href={item.uriPath}>{t(item.label)}</Link>
                </MenuLink>
            </li>
        ));
    };

    return (
        <div>
            <div className="flex items-start gap-2 self-stretch p-[24px_24px_16px_28px] text-xs font-bold uppercase tracking-[0.48px] text-white">
                {t(menuItem.label)}
            </div>

            <ul className="space-y-base px-4">
                {menuItem.menu.map((submenuItem) =>
                    submenuItem.menu.length ? (
                        <li key={submenuItem.label}>
                            <MenuItemRoot
                                defaultOpen={submenuItem.menu
                                    .map((item) => item.uriPath)
                                    .some((item) => item === pathname)}
                            >
                                <MenuItemTrigger>{t(submenuItem.label)}</MenuItemTrigger>
                                <MenuItemContent>
                                    <ul className="space-y-1">
                                        {renderSubmenuItems(submenuItem.menu)}
                                    </ul>
                                </MenuItemContent>
                            </MenuItemRoot>
                        </li>
                    ) : (
                        <li key={submenuItem.label}>
                            <MenuLink
                                isActive={submenuItem.uriPath === activeSubmenuItem?.uriPath}
                                asChild
                            >
                                <Link href={submenuItem.uriPath}>{t(submenuItem.label)}</Link>
                            </MenuLink>
                        </li>
                    ),
                )}
            </ul>
        </div>
    );
};
